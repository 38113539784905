import { gql } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';
import { USER_FIELDS } from '../graphql/userFragment';
import useAuth from './useAuth';
import useGraphQLClient from './useGraphQLClient';
import useToastAlerts from './useToastAlerts';

const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();
  const graphQLClient = useGraphQLClient();
  const { addToast } = useToastAlerts();
  const { logout } = useAuth();

  return useMutation(
    async id => {
      const { deleteUser } = await graphQLClient.request(gql`
        ${USER_FIELDS}

        mutation deleteUser {
          deleteUser(id:${id}) {
            ...userFields
          }
        }
      `);

      addToast(
        `User: ${deleteUser.username} with ID: ${deleteUser.id} is deleted`,
        {
          appearance: 'warning',
          autoDismiss: true,
        },
      );
      return deleteUser;
    },
    {
      onMutate: async userId => {
        await queryClient.cancelQueries('users');

        const previousUsers = queryClient.getQueryData('users');

        queryClient.setQueryData(
          'users',
          previousUsers.filter(user => user.id !== userId),
        );

        return previousUsers;
      },
      onError: err => {
        const statusCode = err?.response?.status || 500;
        if (statusCode === 401) {
          logout();
        }
        if (statusCode === 403) {
          logout();
        }
        console.error({
          message: 'Error fetching users',
          error: err,
          statusCode,
        });
      },
    },
  );
};

export default useDeleteUserMutation;
