import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { USER_FIELDS } from '../graphql/userFragment';
import useAuth from './useAuth';
import useGraphQLClient from './useGraphQLClient';

const useUsersQuery = () => {
  const graphQLClient = useGraphQLClient();
  const { logout } = useAuth();

  return useQuery(
    'users',
    async () => {
      const { Users } = await graphQLClient.request(gql`
        ${USER_FIELDS}

        query getUsers {
          Users {
            ...userFields
          }
        }
      `);
      return Users;
    },
    {
      initialData: [],
      keepPreviousData: true,
      onError: err => {
        const statusCode = err?.response?.status || 500;
        if (statusCode === 401) {
          logout();
        }
        if (statusCode === 403) {
          logout();
        }
        console.error({
          message: 'Error fetching users',
          error: err,
          statusCode,
        });
      },
    },
  );
};

export default useUsersQuery;
