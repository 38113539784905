import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-hook-form';
import Modal from '../Modal';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../form/Input';
import ControlErrorFeedback from '../form/ControlErrorFeedback';

const OnUserDeleteDialog = ({
  selectedUser,
  open,
  setOpen,
  onDelete = () => null,
}) => {
  if (!selectedUser) return null;
  return (
    <Modal
      className="sm:max-w-2xl"
      title="Are you absolutely sure?"
      open={open}
      setOpen={setOpen}
      type="warning"
      content={({ cancelButtonRef }) => (
        <DeleteUserForm
          username={selectedUser.username}
          cancelButtonRef={cancelButtonRef}
          setOpen={setOpen}
          onDelete={onDelete}
        />
      )}
    />
  );
};

const DeleteUserForm = ({ username, cancelButtonRef, setOpen, onDelete }) => {
  const formSchema = yup.object().shape({
    username: yup.string().oneOf([username], 'Username must match'),
  });
  const { register, handleSubmit, control } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { errors } = useFormState({ control });

  const onSubmit = () => {
    setOpen(false);
    onDelete();
  };
  return (
    <div className="pb-2">
      <div className="m-4/5">
        <div className="mt-4">
          This action <b>CANNOT</b> be undone. This will delete the{' '}
          <q>
            <strong>{username}</strong>
          </q>
          user.
        </div>
        <div className="mt-2">Please type in the username to confirm.</div>
        <form id="delete-user-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-3 form-control">
            <label className="label">
              <span className="label-text">Username</span>
            </label>
            <Input
              placeholder="username"
              name="username"
              error={!!errors.username}
              {...register('username')}
            />
            <ControlErrorFeedback errors={errors} name="username" />
          </div>
        </form>
      </div>
      <div className="flex flex-wrap-reverse justify-center px-2 pt-5 md:flex-nowrap">
        <button
          type="button"
          className="m-0 btn btn-ghost"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}>
          Cancel
        </button>
        <button
          form="delete-user-form"
          type="submit"
          className="h-auto max-w-md mb-2 md:ml-2 md:m-0 btn btn-error btn-block">
          I understand the consequenses, delete this user.
        </button>
      </div>
    </div>
  );
};

DeleteUserForm.propTypes = {
  cancelButtonRef: PropTypes.any,
  setOpen: PropTypes.func,
  username: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};
OnUserDeleteDialog.propTypes = {
  selectedUser: PropTypes.any,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default OnUserDeleteDialog;
