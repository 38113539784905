import { yupResolver } from '@hookform/resolvers/yup';
import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import { useForm, useFormState, useFieldArray } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { USER_FIELDS } from '../graphql/userFragment';
import validateTopic from '../helpers/isValidMqtt';
import useAuth from './useAuth';
import useCreateUserMutation from './useCreateUserMutation';
import useGraphQLClient from './useGraphQLClient';
import useToastAlerts from './useToastAlerts';
import useUpdateUserMutation from './useUpdateUserMutation';

const formSchema = yup.object().shape({
  username: yup.string().required(),
  clientID: yup.string(),
  mountPoint: yup.string().max(10).optional(),
  password: yup.string(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  publish: yup.array().of(
    yup.object().shape({
      pattern: yup
        .string()
        .test(
          'mqtt-pattern-validator',
          'Is not a valid MQtt pattern',
          validateTopic,
        )
        .required(),
      qos: yup.number().oneOf([0, 1, 2]).required(),
    }),
  ),
  subscribe: yup.array().of(
    yup.object().shape({
      pattern: yup
        .string()
        .test(
          'mqtt-pattern-validator',
          'Is not a valid MQtt pattern',
          validateTopic,
        )
        .required(),
      qos: yup.number().oneOf([0, 1, 2]).required(),
    }),
  ),
});

const aclFormSchema = yup.object().shape({
  pattern: yup
    .string()
    .test(
      'mqtt-pattern-validator',
      'Is not a valid MQtt pattern',
      validateTopic,
    ),
  qos: yup.number().oneOf([0, 1, 2]).required(),
});

const useUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const { logout } = useAuth();
  const [isEdit, setIsEdit] = useState(id !== '0');
  const { addToast } = useToastAlerts();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const userForm = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {},
  });
  const { control, setValue, watch, setError, register, unregister } = userForm;
  const { errors } = useFormState({ control });
  const publishAclArrayField = useFieldArray({ control, name: 'publish' });
  const publishAclForm = useForm({
    resolver: yupResolver(aclFormSchema),
  });
  const publishAclFormErrors = useFormState({
    control: publishAclForm.control,
  });
  const addPublishAcl = data => {
    publishAclArrayField.append(data);
    publishAclForm.reset();
    publishAclForm.setFocus('pattern');
  };
  const subscribeAclArrayField = useFieldArray({
    control,
    name: 'subscribe',
  });
  const subscribeAclForm = useForm({
    resolver: yupResolver(aclFormSchema),
  });
  const subscribeAclFormErrors = useFormState({
    control: subscribeAclForm.control,
  });
  const addSubscribeAcl = data => {
    subscribeAclArrayField.append(data);
    subscribeAclForm.reset();
    subscribeAclForm.setFocus('pattern');
  };
  const onChangePasswordToggle = () => {
    if (showChangePassword) {
      setShowChangePassword(false);
      unregister('password');
      unregister('confirmPassword');
    } else {
      setShowChangePassword(true);
      register('password');
      register('confirmPassword');
      setValue('password', '');
      setValue('confirmPassword', '');
    }
  };

  const graphQLClient = useGraphQLClient();
  const userQuery = useQuery(
    [`user-${id}`, id],
    async () => {
      try {
        const { User } = await graphQLClient.request(gql`
        ${USER_FIELDS}
        query getUserById {
          User(userID: ${id}) {
            ...userFields
          }
        }
      `);
        return User;
      } catch (error) {
        console.log(error.message);
        addToast('Unexpected issues with the server, retrieving user', {
          appearance: 'error',
          autoDismiss: true,
        });
        history.push('/');
      }
    },
    {
      onError: err => {
        const statusCode = err?.response?.status || 500;
        if (statusCode === 401) {
          logout();
        }
        if (statusCode === 403) {
          logout();
        }
        console.error({
          message: 'Error fetching users',
          error: err,
          statusCode,
        });
      },
      enabled: isEdit,
      keepPreviousData: true,
    },
  );

  const createUserMutation = useCreateUserMutation((status, response) => {
    if (status === 'ok') {
      addToast(`User ${response.username} with ID: ${response.id} created`, {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push(`/user/${response.id}`);
      setIsEdit(true);
      setSelectedUser(response);
    }
    if (status === 'error') {
      console.log(response.error);
      addToast('Unexpected issues with the server, creating user', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  });
  const updateUserMutation = useUpdateUserMutation((status, response) => {
    if (status === 'ok') {
      addToast(`User ${response.username} with ID: ${response.id} updated`, {
        appearance: 'success',
        autoDismiss: true,
      });
      setSelectedUser(response);
    }
    if (status === 'error') {
      console.log(response.message);
      addToast('Unexpected issues with the server, updating user', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  });

  useEffect(() => {
    const user = userQuery.data;
    if (user) {
      setSelectedUser(user);

      setValue('username', user.username);
      setValue('clientID', user.clientID || '');
      setValue('mountPoint', user.mountPoint || '');
      setValue('publish', user.publish);
      setValue('subscribe', user.subscribe);
    }
  }, [userQuery.data]);

  const onSubmit = data => {
    if ((!isEdit || showChangePassword) && watch('password') === '') {
      setError(
        'password',
        {
          message: 'Password is required',
          type: 'required',
        },
        { shouldFocus: true },
      );
      return;
    }
    const { username, password, clientID, mountPoint, publish, subscribe } =
      data;
    if (isEdit) {
      updateUserMutation.mutate({
        id: +id,
        username,
        password,
        clientID,
        mountPoint,
        publish,
        subscribe,
      });
    } else {
      createUserMutation.mutate({
        username,
        password,
        clientID,
        mountPoint,
        publish,
        subscribe,
      });
    }

    if (showChangePassword) {
      onChangePasswordToggle();
    }

    publishAclForm.reset();
    subscribeAclForm.reset();
  };

  return {
    ...userForm,
    isEdit,
    errors,
    history,
    onSubmit,
    publishAclArrayField,
    publishAclForm,
    publishAclFormErrors,
    addPublishAcl,
    subscribeAclArrayField,
    subscribeAclForm,
    addSubscribeAcl,
    subscribeAclFormErrors,
    selectedUser,
    onChangePasswordToggle,
    showChangePassword,
  };
};

export default useUser;
