import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  SearchIcon,
  SwitchVerticalIcon,
} from '@heroicons/react/outline';
import OnUserDeleteDialog from './components/user/OnUserDeleteDialog';
import useUsers from './hooks/useUsers';

const Users = () => {
  const {
    setSearchTerm,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    onGlobalFilterChange,
    searchTerm,
    setPageSize,
    headerGroups,
    page,
    previousPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    canNextPage,
    pageOptions,
    selectedUser,
    isDeleting,
    setIsDeleting,
    state: { pageIndex },
    onDeletedUser,
    usersQuery: { isLoading },
  } = useUsers();

  return (
    <div className="pb-32 mx-5 mt-5">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <span className="flex w-3 h-3">
            <span className="absolute inline-flex w-full h-full bg-gray-100 rounded-full opacity-75 animate-ping"></span>
            <span className="relative inline-flex w-3 h-3 bg-gray-300 rounded-full"></span>
          </span>
          <span className="ml-2">Loading...</span>
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center mx-auto md:w-5/6 lg:w-4/6">
            <div className="flex flex-col justify-center mb-3 sm:justify-between sm:flex-row">
              <div className="mb-2 form-control sm:mb-0">
                <div className="relative">
                  <input
                    value={searchTerm || ''}
                    onChange={e => {
                      setSearchTerm(e.target.value);
                      onGlobalFilterChange(e.target.value);
                    }}
                    type="text"
                    placeholder="Search"
                    className="w-full pr-16 input input-bordered"
                  />
                  <button className="absolute top-0 right-0 bg-gray-600 rounded-l-none btn btn-disabled">
                    <SearchIcon className="w-5 h-5 text-white stroke-current" />
                  </button>
                </div>
              </div>

              <div className="form-control">
                <select
                  id="per_page"
                  className="w-full font-normal text-gray-400 border-gray-300 input-bordered border-1 select"
                  onChange={e => setPageSize(+e.target.value)}>
                  {[5, 10, 25, 50, 100].map(size => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table
                className="table w-full table-compact"
                {...getTableProps()}>
                <thead>
                  {headerGroups.map(({ getHeaderGroupProps, headers }) => {
                    const { key, ...restHeaderGroupProps } =
                      getHeaderGroupProps();
                    return (
                      <tr
                        key={key}
                        className={`text-gray-600 uppercase bg-gray-200`}
                        {...restHeaderGroupProps}>
                        {headers.map(({ className, ...column }) => {
                          const { key, ...restHeaderProps } =
                            column.getHeaderProps(
                              column.getSortByToggleProps(),
                            );
                          return (
                            <th
                              key={key}
                              className={`select-none ${className}`}
                              {...restHeaderProps}>
                              {column.render('Header')}
                              {column.canSort && (
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ArrowSmDownIcon className="inline w-3 ml-1" />
                                    ) : (
                                      <ArrowSmUpIcon className="inline w-3 ml-1" />
                                    )
                                  ) : (
                                    <SwitchVerticalIcon className="inline w-3 ml-1" />
                                  )}
                                </span>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody
                  className="text-sm font-light text-gray-600"
                  {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps();
                    return (
                      <tr key={key} className="h-0 p-0 m-0" {...restRowProps}>
                        {row.cells.map(({ getCellProps, render }) => {
                          const { key, ...restCellProps } = getCellProps();
                          return (
                            <td
                              key={key}
                              className="h-0 p-0 m-0"
                              {...restCellProps}>
                              {render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="block mt-3 text-center btn-group">
            <button
              onClick={previousPage}
              className={`btn btn-ghost ${
                !canPreviousPage && 'opacity-60 btn-disabled'
              }`}>
              ← Prev
            </button>

            <input
              type="string"
              className="w-20 mx-3 text-center border-gray-300 border-1 conten input outline-white"
              value={pageIndex + 1}
              onChange={e => gotoPage(e.target.value ? +e.target.value - 1 : 0)}
            />

            <button
              onClick={nextPage}
              className={`btn btn-ghost ${
                !canNextPage && 'opacity-60 btn-disabled'
              }`}>
              Next →
            </button>
          </div>
          <div className="mt-2 text-sm text-center">
            <span>
              Page <strong>{pageIndex + 1}</strong> of{' '}
              <strong>{pageOptions.length}</strong>
            </span>
          </div>
        </>
      )}
      <OnUserDeleteDialog
        selectedUser={selectedUser}
        open={!!selectedUser && isDeleting}
        setOpen={setIsDeleting}
        onDelete={onDeletedUser}
      />
    </div>
  );
};

export default Users;
