import { GraphQLClient } from 'graphql-request';

const useGraphQLClient = () => {
  const graphQLClient = new GraphQLClient(
    `${process.env.REACT_APP_BACKEND_API_URL || window.location.protocol + "//" + window.location.host}/api/query`,
    {
      credentials: 'include',
    },
  );
  return graphQLClient;
};

export default useGraphQLClient;
