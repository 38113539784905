import { TrashIcon, KeyIcon } from '@heroicons/react/outline';
import ControlErrorFeedback from './components/form/ControlErrorFeedback';
import Input from './components/form/Input';
import Fieldset from './components/form/Fieldset';
import useUser from './hooks/useUser';
import Breadcrumb from '././components/Breadcrumb';

const User = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    errors,
    history,
    publishAclForm,
    publishAclArrayField,
    addPublishAcl,
    publishAclFormErrors,
    subscribeAclForm,
    subscribeAclArrayField,
    addSubscribeAcl,
    subscribeAclFormErrors,
    isEdit,
    formState: { isDirty },
    onChangePasswordToggle,
    showChangePassword,
  } = useUser();

  return (
    <div className="h-screen">
      <div className="m-auto md:w-3/4 lg:w-4/5 card xl:w-3/5">
        <Breadcrumb
          links={[{ url: '/users', title: 'Access Management' }]}
          current={`${isEdit ? 'Edit' : 'Add new'} access`}
        />
        <div className="card-body">
          <div className={`text-xl ${isEdit && 'flex justify-between'}`}>
            <div>{isEdit ? 'Edit' : 'Add new'} access </div>
            {isEdit && (
              <div>
                <button
                  type="button"
                  onClick={onChangePasswordToggle}
                  className="text-xs btn btn-outline btn-dark btn-sm">
                  <KeyIcon className="w-5 h-5 mr-4" />{' '}
                  {showChangePassword ? 'Hide' : 'Change'} Password
                </button>
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-gray-400">
            Fields with (*) are required.
          </p>
          <form id="userForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col flex-1 mt-4 lg:flex-row">
              <div className="flex-1 lg:mr-2 form-control">
                <label className="label">
                  <span className="label-text">Username (*)</span>
                </label>
                <Input
                  placeholder="client id"
                  name="username"
                  error={!!errors.username}
                  {...register('username')}
                />

                <ControlErrorFeedback errors={errors} name="username" />
              </div>
              <div className="flex-1 lg:ml-2 form-control">
                <label className="label">
                  <span className="label-text">Client ID</span>
                </label>
                <Input
                  placeholder="client id"
                  name="clientID"
                  error={!!errors.clientID}
                  {...register('clientID')}
                />
                <ControlErrorFeedback errors={errors} name="clientID" />
              </div>
            </div>
            {(!isEdit || showChangePassword) && (
              <>
                <Fieldset label={`${isEdit ? 'Change p' : 'P'}assword (*)`}>
                  <div className="relative flex flex-col flex-1 -mt-4 lg:flex-row">
                    <div className="flex-1 lg:mr-2 form-control">
                      <Input
                        type="password"
                        placeholder="password"
                        error={!!errors.password}
                        {...register('password')}
                      />
                    </div>
                    <div className="flex-1 mt-3 lg:mt-0 lg:ml-2 form-control">
                      <Input
                        type="password"
                        placeholder="confirm password"
                        error={!!errors.confirmPassword}
                        {...register('confirmPassword')}
                      />
                    </div>
                  </div>
                </Fieldset>
                <ControlErrorFeedback errors={errors} name="password" />
                <ControlErrorFeedback errors={errors} name="confirmPassword" />
              </>
            )}
            <div className="flex flex-col flex-1 lg:flex-row">
              <div className="flex-1 lg:mr-2 form-control">
                <label className="label">
                  <span className="label-text">Mount point</span>
                </label>
                <Input
                  placeholder="empty by default"
                  name="mountPoint"
                  error={!!errors.mountPoint}
                  {...register('mountPoint')}
                />
                <ControlErrorFeedback errors={errors} name="mountPoint" />
              </div>
            </div>
          </form>
          <Fieldset
            label={
              <>
                Publish ACL{' '}
                <div className="badge badge-secondary">
                  {publishAclArrayField.fields.length}
                </div>
              </>
            }>
            <form
              id="publishAclForm"
              onSubmit={publishAclForm.handleSubmit(addPublishAcl)}
              className="relative -mt-4">
              <div className="relative rounded-md shadow-sm">
                <input
                  aria-invalid={!!publishAclFormErrors.errors.pattern}
                  placeholder="Add pattern"
                  className={`w-full pr-44 input input-bordered ${
                    !!publishAclFormErrors.errors.pattern && 'input-error'
                  }`}
                  {...publishAclForm.register('pattern')}
                />
                <div className="absolute inset-y-0 right-0 flex items-center -ml-4">
                  <label className="mr-2 text-sm text-gray-400">
                    QoS
                    <select
                      {...publishAclForm.register('qos')}
                      defaultValue={0}
                      className="h-full py-0 pl-2 mr-16 text-base text-gray-500 bg-transparent border-transparent rounded-md select ">
                      <option value={0}>0</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                    </select>
                  </label>
                </div>
              </div>
              <ControlErrorFeedback
                errors={publishAclFormErrors.errors}
                name="pattern"
              />
              <button
                form="publishAclForm"
                type="submit"
                className="absolute top-0 right-0 p-3 text-sm border-none rounded-l-none btn btn-secondary">
                Add
              </button>
            </form>
            {publishAclArrayField.fields.map((item, index) => (
              <div key={item.id} className="relative mt-2">
                <div className="relative rounded-md shadow-sm">
                  <input
                    placeholder="Add pattern"
                    className={`w-full pr-44 input input-bordered ${
                      !!errors.publish?.[index]?.pattern && 'input-error'
                    }`}
                    {...register(`publish.${index}.pattern`)}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center -ml-4">
                    <label className="mr-2 text-sm text-gray-400">
                      QoS
                      <select
                        {...register(`publish.${index}.qos`)}
                        defaultValue={1}
                        className="h-full py-0 pl-2 mr-16 text-base text-gray-500 bg-transparent border-transparent rounded-md select ">
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                      </select>
                    </label>
                  </div>
                </div>
                <ControlErrorFeedback
                  errors={errors}
                  name={`publish.${index}.pattern`}
                />
                <button
                  type="button"
                  onClick={() => publishAclArrayField.remove(index)}
                  className="absolute top-0 right-0 rounded-l-none btn btn-outline btn-error">
                  <TrashIcon className="w-5 h-5 stroke-current" />
                </button>
              </div>
            ))}
          </Fieldset>
          <ControlErrorFeedback errors={errors} name="publish" />

          <Fieldset
            label={
              <>
                Subscribe ACL{' '}
                <div className="badge badge-primary">
                  {subscribeAclArrayField.fields.length}
                </div>
              </>
            }>
            <form
              id="subscribeAclForm"
              onSubmit={subscribeAclForm.handleSubmit(addSubscribeAcl)}
              className="relative -mt-4">
              <div className="relative rounded-md shadow-sm">
                <input
                  aria-invalid={!!subscribeAclFormErrors.errors.pattern}
                  placeholder="Add pattern"
                  className={`w-full pr-44 input input-bordered ${
                    !!subscribeAclFormErrors.errors.pattern && 'input-error'
                  }`}
                  {...subscribeAclForm.register('pattern')}
                />
                <div className="absolute inset-y-0 right-0 flex items-center -ml-4">
                  <label className="mr-2 text-sm text-gray-400">
                    QoS
                    <select
                      {...subscribeAclForm.register('qos')}
                      defaultValue={0}
                      className="h-full py-0 pl-2 mr-16 text-base text-gray-500 bg-transparent border-transparent rounded-md select ">
                      <option value={0}>0</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                    </select>
                  </label>
                </div>
              </div>
              <ControlErrorFeedback
                errors={subscribeAclFormErrors.errors}
                name="pattern"
              />
              <button
                form="subscribeAclForm"
                type="submit"
                className="absolute top-0 right-0 p-3 text-sm border-none rounded-l-none btn btn-primary">
                Add
              </button>
            </form>
            {subscribeAclArrayField.fields.map((item, index) => (
              <div key={item.id || index} className="relative mt-2">
                <div className="relative rounded-md shadow-sm">
                  <input
                    placeholder="Add pattern"
                    className={`w-full pr-44 input input-bordered ${
                      !!errors.subscribe?.[index]?.pattern && 'input-error'
                    }`}
                    {...register(`subscribe.${index}.pattern`)}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center -ml-4">
                    <label className="mr-2 text-sm text-gray-400">
                      QoS
                      <select
                        {...register(`subscribe.${index}.qos`)}
                        defaultValue={1}
                        className="h-full py-0 pl-2 mr-16 text-base text-gray-500 bg-transparent border-transparent rounded-md select ">
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                      </select>
                    </label>
                  </div>
                </div>
                <ControlErrorFeedback
                  errors={errors}
                  name={`subscribe.${index}.pattern`}
                />
                <button
                  type="button"
                  onClick={() => subscribeAclArrayField.remove(index)}
                  className="absolute top-0 right-0 rounded-l-none btn btn-outline btn-error">
                  <TrashIcon className="w-5 h-5 stroke-current" />
                </button>
              </div>
            ))}
          </Fieldset>

          <ControlErrorFeedback errors={errors} name="subscribe" />

          <div className="flex flex-wrap-reverse justify-center px-2 pt-5 md:flex-nowrap">
            <button
              type="button"
              onClick={() => history.goBack()}
              className="block btn btn-ghost btn-wide md:mr-2">
              Back
            </button>
            <button
              form="userForm"
              type="submit"
              disabled={!isDirty}
              className={`block btn btn-wide md:ml-2 ${
                isEdit ? 'btn-primary' : 'btn-accent'
              }`}>
              {isEdit ? 'Update' : 'Create'} user
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

User.propTypes = {};

export default User;
