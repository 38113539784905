import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import styled from 'styled-components';
import tw from 'twin.macro';

const Message = styled.span`
  ${tw`block mt-1 text-sm font-light leading-3 text-red-400 `}
`;

const ControlErrorFeedback = ({ errors, name }) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => (
      <label className="label">
        <Message role="alert">{message}</Message>
      </label>
    )}
  />
);

ControlErrorFeedback.propTypes = {
  errors: PropTypes.any,
  name: PropTypes.string,
};

export default ControlErrorFeedback;
