import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { USER_FIELDS } from '../graphql/userFragment';
import useGraphQLClient from './useGraphQLClient';

const useUpdateUserMutation = callback => {
  const graphQLClient = useGraphQLClient();

  return useMutation(async user => {
    try {
      const { updateUser } = await graphQLClient.request(
        gql`
          ${USER_FIELDS}
          mutation updateUser($updateUser: UpdateUserInput!) {
            updateUser(data: $updateUser) {
              ...userFields
            }
          }
        `,
        {
          updateUser: {
            ...user,
          },
        },
      );
      callback('ok', updateUser);

      return updateUser;
    } catch (error) {
      callback('error', error);
    }
  });
};

export default useUpdateUserMutation;
