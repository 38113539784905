import { useLocation } from 'react-router-dom';

const useLogin = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  const login = () => {
    window.localStorage.setItem('redirectTo', from.pathname);
    window.location = `${process.env.REACT_APP_BACKEND_API_URL || window.location.protocol + "//" + window.location.host}/auth/login`;
  };

  return {
    login,
  };
};

export default useLogin;
