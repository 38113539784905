/* eslint-disable react/prop-types */
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import ActionButton from '../components/ActionButton';
import useDeleteUserMutation from './useDeleteUserMutation';
import useUsersQuery from './useUsersQuery';

export const useUsers = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const userQuery = useUsersQuery();
  const deleteUser = useDeleteUserMutation();

  const { data } = userQuery;
  const onDeletedUser = () => {
    deleteUser.mutate(selectedUser.id);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        className: 'relative',
      },
      {
        Header: 'Client id',
        accessor: 'clientID',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Publish ACL',
        accessor: 'publish',
        className: 'text-center',
        disableSortBy: true,
        Cell({ value = [] }) {
          return (
            <span className="block w-8 px-3 py-1 m-auto text-xs font-bold bg-blue-200 rounded-full text-white-600">
              {value.length}
            </span>
          );
        },
      },
      {
        Header: 'Subscribe ACL',
        accessor: 'subscribe',
        disableSortBy: true,
        Cell({ value = [] }) {
          return (
            <span className="block w-8 px-3 py-1 m-auto text-xs font-bold bg-purple-200 rounded-full text-white-600">
              {value.length}
            </span>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: '',
        disableSortBy: true,
        className: 'text-center',
        stilos: 'text-center',
        Cell({
          cell: {
            row: { original },
          },
        }) {
          return (
            <div className="text-center">
              <ActionButton
                title="Edit or view"
                onClick={() => history.push(`/user/${original.id}`)}
                icon={PencilAltIcon}
              />
              <ActionButton
                title="Delete"
                icon={TrashIcon}
                onClick={() => handleDeleteUserModal(original)}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const { setGlobalFilter } = table;
  const onGlobalFilterChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleDeleteUserModal = ({ id, username }) => {
    setSelectedUser({ id, username });
    setIsDeleting(true);
  };

  return {
    ...table,
    selectedUser,
    isDeleting,
    searchTerm,
    setIsDeleting,
    setSearchTerm,
    onGlobalFilterChange,
    usersQuery: useUsersQuery,
    onDeletedUser,
  };
};

export default useUsers;
