import { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { PropTypes } from 'prop-types';

const getUserFromCookie = () => {
  const identityField = 'identity';
  const token = document.cookie
    .split('; ')
    .find(row => row.trim().startsWith(`${identityField}=`));
  if (token) {
    return jwt_decode(token.split('=')[1]);
  }
  return null;
};

const initialState = {
  isAuthenticated: !!getUserFromCookie(),
  user: getUserFromCookie() || {},
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <AuthContext.Provider value={{ ...state, setState }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
