function isValidHash(str) {
  let count = 0;
  for (let character of str) {
    if (character === '#') count += 1;
  }
  if (count === 0) return true;
  return count === 1 && str.indexOf('#') === str.length - 1;
}

function isValidPlus(str) {
  if (!str.includes('+') || str.length === 1) return true;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '+') {
      if (i === 0) {
        if (str[i + 1] !== '/') return false;
      } else if (i === str.length - 1) {
        if (str[i - 1] !== '/') return false;
      } else if (str[i - 1] !== '/' || str[i + 1] !== '/') {
        return false;
      }
    }
  }
  return true;
}

export default function isValidMqtt(str) {
  if (str.length === 0) return false;
  return isValidHash(str) && isValidPlus(str);
}
/**
 * Validate a topic to see if it's valid or not.
 * A topic is valid if it follow below rules:
 * - Rule #1: If any part of the topic is not `+` or `#`, then it must not contain `+` and '#'
 * - Rule #2: Part `#` must be located at the end of the mailbox
 *
 * @param {String} topic - A topic
 * @returns {Boolean} If the topic is valid, returns true. Otherwise, returns false.
 */
export function validateTopic(topic) {
  var parts = topic.split('/');

  for (var i = 0; i < parts.length; i++) {
    if (parts[i] === '+') {
      continue;
    }

    if (parts[i] === '#') {
      // for Rule #2
      return i === parts.length - 1;
    }

    if (parts[i].indexOf('+') !== -1 || parts[i].indexOf('#') !== -1) {
      return false;
    }
  }

  return true;
}
