import backgroundImage from './assets/img/login-bg.jpg';
import useLogin from './hooks/useLogin';

const Login = () => {
  const { login } = useLogin();
  return (
    <div className="flex min-h-screen bg-white">
      <div
        className="hidden w-1/2 bg-cover md:block"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div className="max-w-lg px-4 py-5 mx-auto my-24 shadow-none md:w-1/2">
        <div className="p-0 font-sans text-center">
          <h1 className="text-3xl font-medium text-gray-800">Login access</h1>
          <p className="p-1 mt-3 text-gray-700">
            Sign in and you will have access to the MQTT-Auth App
          </p>
        </div>
        <div className="text-center mt-7">
          <button
            type="submit"
            onClick={login}
            className="btn btn-outline btn-ghost">
            Login with Single Sign-On
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
