import { gql } from 'graphql-request';
import { useMutation } from 'react-query';
import { USER_FIELDS } from '../graphql/userFragment';
import useAuth from './useAuth';
import useGraphQLClient from './useGraphQLClient';

const useCreateUserMutation = callback => {
  const graphQLClient = useGraphQLClient();
  const { logout } = useAuth();

  return useMutation(async user => {
    try {
      const { createUser } = await graphQLClient.request(
        gql`
          ${USER_FIELDS}
          mutation createUser($createUser: UserInput!) {
            createUser(data: $createUser) {
              ...userFields
            }
          }
        `,
        {
          createUser: {
            ...user,
          },
        },
      );
      callback('ok', createUser);
      return createUser;
    } catch (error) {
      const statusCode = error?.response?.status || 500;
      if (statusCode === 401) {
        console.log('401 HA OCURRIDO');
        logout();
      }
      if (statusCode === 403) {
        logout();
      }
      console.error({
        message: 'Error fetching users',
        error,
        statusCode,
      });
      callback('error', error);
    }
  });
};

export default useCreateUserMutation;
