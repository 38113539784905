import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ links, current }) => {
  return (
    <div className="flex ml-5 text-sm breadcrumbs">
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.url}>{link.title}</Link>
          </li>
        ))}
        <li>{current}</li>
      </ul>
    </div>
  );
};

Breadcrumb.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  current: PropTypes.string,
};

export default Breadcrumb;
