import PropTypes from 'prop-types';
import { Fragment, useMemo, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';

const Modal = ({ title, content: Content, open, setOpen, type, ...props }) => {
  const cancelButtonRef = useRef(null);
  const modalType = useMemo(
    () => ({
      warning: {
        icon: (
          <ExclamationIcon
            className="w-6 h-6 text-red-600"
            aria-hidden="true"
          />
        ),
        hoverColor: 'bg-red-100',
      },
      confirm: {
        icon: (
          <CheckCircleIcon
            className="w-6 h-6 text-green-600"
            aria-hidden="true"
          />
        ),
        hoverColor: 'bg-green-100',
      },
      info: {
        icon: (
          <InformationCircleIcon
            className="w-6 h-6 text-blue-600"
            aria-hidden="true"
          />
        ),
        hoverColor: 'bg-blue-100',
      },
    }),
    [],
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}>
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              {...props}
              className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className={` mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${modalType[type].hoverColor}`}>
                    {modalType[type].icon}
                  </div>
                  <div className="w-full mt-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="mt-2 text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-2">
                  <Content cancelButtonRef={cancelButtonRef} />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  content: PropTypes.any,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['warning', 'info', 'confirm']).isRequired,
};

export default Modal;
