import { CloudIcon, LogoutIcon, UserAddIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const Header = ({ appName }) => {
  const { logout } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <header className="text-gray-600 body-font">
      <div className="container flex flex-col flex-wrap items-center p-5 mx-auto md:flex-row">
        <Link
          to="/"
          className="flex items-center mb-4 font-medium text-gray-900 btn btn-ghost hover:bg-white title-font md:mb-0">
          <CloudIcon className="w-6 h-6" />
          <span className="ml-3 text-xl">{appName}</span>
        </Link>
        <nav className="flex flex-wrap items-center justify-center text-base md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400">
          <Link to="/" className="mr-5 text-gray-900 btn btn-sm btn-link">
            Access management
          </Link>
        </nav>
        {pathname === '/' ? (
          <button
            onClick={() => history.push('/user/0')}
            className="btn btn-sm">
            Add new access
            <UserAddIcon className="w-4 h-4 ml-2" />
          </button>
        ) : null}
        <button onClick={logout} className="ml-3 btn btn-ghost btn-sm">
          Logout
          <LogoutIcon className="w-4 h-4 ml-2" />
        </button>
      </div>
    </header>
  );
};

Header.propTypes = {
  appName: PropTypes.string,
};

export default Header;
