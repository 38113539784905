import PropTypes from 'prop-types';

const ActionButton = ({ title, icon: Icon, ...props }) => (
  <div
    {...props}
    title={title}
    className="inline-grid w-5 mr-2 text-gray-600 hover:text-gray-700 btn btn-link">
    <Icon className="w-5 h-5 stroke-current" />
  </div>
);

ActionButton.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
};

export default ActionButton;
