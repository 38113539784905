const useAuth = () => {
  const logout = () => {
    window.location = `${process.env.REACT_APP_BACKEND_API_URL || window.location.protocol + "//" + window.location.host}/auth/logout`;
  };

  return {
    logout,
  };
};

export default useAuth;
