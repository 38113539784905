import PropTypes from 'prop-types';
import { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Container from './components/Container';
import Header from './components/Header';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import Login from './Login';
import './styles.css';
import User from './User';
import Users from './Users';

const queryClient = new QueryClient();

const App = () => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} />
        </ToastProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

const AppRouter = () => {
  let { isAuthenticated } = useContext(AuthContext);

  // eslint-disable-next-line no-unused-vars
  const isAuthorized = (roles = []) => isAuthenticated;
  // const isAuthorized = roles => {
  //   if (keycloak && roles) {
  //     if (roles.length === 0) {
  //       return true;
  //     }
  //     return roles.some(r => {
  //       const realm = keycloak.hasRealmRole(r);
  //       const resource = keycloak.hasResourceRole(r);
  //       return realm || resource;
  //     });
  //   }
  //   return false;
  // };

  return (
    <Router>
      <Switch>
        {!isAuthenticated && (
          <PublicRoute exact path="/login" component={Login} />
        )}

        <PrivateRoute
          roles={[]}
          isAuthenticated={isAuthenticated}
          isAuthorized={isAuthorized}
          exact
          path="/"
          component={Users}
        />

        <PrivateRoute
          roles={[]}
          isAuthenticated={isAuthenticated}
          isAuthorized={isAuthorized}
          exact
          path="/user/:id"
          component={User}
        />

        <Route
          path="/login/success"
          render={() => {
            const redirectTo = window.localStorage.getItem('redirectTo') || '/';
            window.localStorage.removeItem('redirectTo');
            return <Redirect to={redirectTo} />;
          }}
        />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export const PrivateRoute = ({
  isAuthenticated,
  isAuthorized,
  component: Component,
  roles = [],
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated && isAuthorized(roles) ? (
          <Container>
            <Header appName="MQTT-Auth" />
            <Component {...props} />
          </Container>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  isAuthorized: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} component={props => <Component {...props} />} />;
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default App;
