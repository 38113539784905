import PropTypes from 'prop-types';
import S from './styled';

const Fieldset = ({ label, children }) => {
  return (
    <S.Fieldset>
      <S.Legend>
        <S.Span>{label}</S.Span>
      </S.Legend>
      {children}
    </S.Fieldset>
  );
};

Fieldset.propTypes = {
  /**
   * Label for the fieldset
   *
   * Could be a string or an element
   *
   * @type {string|ReactElement}
   */
  label: PropTypes.any.isRequired,
  /**
   * Children elements
   *
   * @type {ReactElement}
   */
  children: PropTypes.any,
};

export default Fieldset;
