import { gql } from 'graphql-request';

export const USER_FIELDS = gql`
  fragment userFields on User {
    id
    username
    clientID
    mountPoint
    subscribe {
      pattern
      qos
    }
    publish {
      pattern
      qos
    }
  }
`;
