import styled from 'styled-components';
import tw from 'twin.macro';

const Fieldset = styled.fieldset`
  ${tw`p-4 mt-1 border-2 border-dashed rounded-lg form-control`}
  & {
    legend {
    }
  }
`;

const Legend = styled.legend`
  ${tw`label`}
`;

const Span = styled.span`
  ${tw`label-text`}
`;

export default { Fieldset, Legend, Span };
