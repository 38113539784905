import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const Input = styled.input.attrs(({ error, type }) => ({
  type: type || 'text',
  'aria-invalid': error,
}))`
  ${tw`input input-bordered`}
  ${({ error }) =>
    error &&
    css`
      ${tw`input-error`}
    `};
`;

Input.propTypes = {
  error: PropTypes.bool,
  type: PropTypes.string,
};

export default Input;
